import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { Button, Paper, Grid, TextField, InputAdornment, IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { MyProfileNav } from '../../elements'
import { globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import InfoIcon from '@material-ui/icons/Info';
import { trackActivity } from '../../../utils/mixpanel';
const ChangePassword = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState({password_1:false, password_2:false, password_3:false})
    const [userInfo, setUserInfo] = useState({old_password:'',password:'',confirm_password:''});
    const [error, setError] = useState({});
    const onFieldChange = (keyParam, value) => {
        setUserInfo({...userInfo, [keyParam]:value});
    }
    const onChangePwd = () => {
        let validationInputs = {};
        validationInputs = {
            old_password: userInfo?.old_password?.trim().length?regexConstants.password.test(userInfo.old_password) ? '' :'Please enter valid Old Password':'Old Password is required',
            password: userInfo?.password?.trim().length ?regexConstants.password.test(userInfo.password)? '' : 'Please enter valid New Password' : 'New Password is required',
            confirm_password: userInfo?.confirm_password?.trim().length ? userInfo.confirm_password === userInfo.password ? '':'Confirm Password should be same as New Password' : 'Confirm Password is required',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            globalPostService('api/password-change/', userInfo)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    setUserInfo({old_password:'',password:'',confirm_password:''});
                }else{
                    // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setLoading(false);
            })
        }else{
            setError(validationInputs);
        }
    }
    return(
        <section className='roles-management' style={{margin:'24px 0 0 276px'}}>
            <MyProfileNav />
            <Paper className="console-forms-fields" style={{padding:'15px'}}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <Grid container spacing={1}>
                            <Grid item md={12}> 
                                <TextField 
                                    required
                                    type={showPassword.password_1 ? 'text':'password'}
                                    id='old_password'
                                    label='Old Password'
                                    value={userInfo?.old_password||''}
                                    fullWidth
                                    margin="normal"
                                    error={error?.old_password?true:false}
                                    helperText={error?.old_password||''}
                                    onChange={(e) => onFieldChange('old_password', e.target.value)}
                                    onFocus={() => setError({...error, 'old_password':''})}
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                          <IconButton
                                            onClick={(e) => console.log('')}
                                            onMouseDown={(e) => setShowPassword({...showPassword, password_1:!showPassword.password_1})}
                                          >
                                            {showPassword.password_1 ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>,
                                    }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}> 
                                <TextField 
                                    required
                                    type={showPassword.password_2 ? 'text':'password'}
                                    id='password'
                                    label={
                                        <span>
                                          New Password 
                                          <Tooltip placement='right' title={
                                            <>  
                                            <ul className="list-unstyled">
                                                <li>Password Rules:</li>
                                                <li>- Password must contain minimum of 8 characters</li>
                                                <li>- Password must contain at least 1 number</li>
                                                <li>- Password must contain at least 1 uppercase character</li>
                                                <li>- Password must contain at least 1 lowercase character</li>
                                                <li>- Password must contain at least 1 special character</li>
                                            </ul>
                                            </>
                                          } 
                                          arrow>
                                                <InfoIcon fontSize='small' style={{verticalAlign:'bottom', marginLeft:'3px'}} />
                                          </Tooltip>
                                        </span>
                                      }
                                    value={userInfo?.password||''}
                                    fullWidth
                                    margin="normal"
                                    error={error?.password?true:false}
                                    helperText={error?.password||''}
                                    onChange={(e) => onFieldChange('password', e.target.value)}
                                    onFocus={() => setError({...error, 'password':''})}
                                    InputLabelProps={{shrink: true, style:{pointerEvents:'auto'}}}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                          <IconButton
                                            onMouseDown={(e) => setShowPassword({...showPassword, password_2:!showPassword.password_2})}
                                          >
                                            {showPassword.password_2 ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>,
                                    }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}> 
                                <TextField 
                                    required
                                    type={showPassword.password_3 ? 'text':'password'}
                                    id='confirm_password'
                                    label='Confirm Password'
                                    value={userInfo?.confirm_password||''}
                                    fullWidth
                                    margin="normal"
                                    error={error?.confirm_password?true:false}
                                    helperText={error?.confirm_password||''}
                                    onChange={(e) => onFieldChange('confirm_password', e.target.value)}
                                    onFocus={() => setError({...error, 'confirm_password':''})}
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                          <IconButton
                                            onMouseDown={(e) => setShowPassword({...showPassword, password_3:!showPassword.password_3})}
                                          >
                                            {showPassword.password_3 ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>,
                                    }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}> 
                                <Button disabled={isLoading} onClick={onChangePwd} color='primary' size='small' variant='contained'>
                                    {isLoading ? <CircularProgress size={20} />:'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Paper>
            
        </section>
    )
}
export default ChangePassword;